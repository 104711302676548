import React, { useState, useEffect } from "react";
import "./InfoIcon.css";

function InfoIcon() {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false); // Tooltip visibility

  // Show the tooltip when the info icon is clicked
  const handleInfoIconClick = (e) => {
    e.stopPropagation(); // Prevent this click from closing the tooltip
    setIsTooltipVisible(true); // Show tooltip
    console.log("Tooltip opened"); // Debug
  };

  // Handle outside clicks to close the tooltip
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        !e.target.closest(".tooltip") && // Check if click is outside the tooltip
        !e.target.closest(".info-icon") // Check if click is outside the info icon
      ) {
        console.log("Clicked outside. Closing tooltip."); // Debug
        setIsTooltipVisible(false); // Close the tooltip
      }
    };

    if (isTooltipVisible) {
      document.addEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick); // Cleanup listener
    };
  }, [isTooltipVisible]);

  return (
    <div className="info-icon-container">
      {/* Info Icon with the emoji 𝒊 */}
      <div
        className="info-icon"
        onClick={handleInfoIconClick} // Trigger tooltip display
      >
        𝒊
      </div>

      {/* Tooltip */}
      {isTooltipVisible && (
        <div
          className="tooltip"
          onClick={(e) => {
            e.stopPropagation(); // Prevent closing tooltip
            console.log("Tooltip clicked"); // Debug
          }}
        >
          <p>
            ☀️ Start your day with this app <br />
            📌 Pin it to your browser… and let productivity flow! <br />
            🌐 Your tasks stay saved for one week in your browser—come back
            anytime you need 🤗 <br />
            ❓ Need more?{" "}
          </p>
          {/* Create Account Button */}
          <button
            className="create-account-button"
            onClick={() => {
              console.log("Create Account button clicked (no action yet)");
            }}
          >
            👉 Create a free account
          </button>
        </div>
      )}
    </div>
  );
}

export default InfoIcon;