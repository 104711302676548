import React, { useState } from 'react';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_green.css';
import Rating from 'react-rating-stars-component';
import { useNavigate } from 'react-router-dom';
import emojiFallingSoundFile from '../sounds/emoji-falling.wav';
import pipeImage from '../assets/pipe-icon.png';
import pipeTravelSoundFile from '../sounds/pipe-travel.wav';
import './AddTask.css';

function AddTask() {
  const [task, setTask] = useState('');
  const [importance, setImportance] = useState(0);
  const [deadline, setDeadline] = useState(new Date());
  const [tasks, setTasks] = useState(() => {
    const savedTasks = localStorage.getItem('tasks');
    return savedTasks ? JSON.parse(savedTasks) : [];
  });
  const [emojiVisible, setEmojiVisible] = useState(false); // State for emoji visibility
  const navigate = useNavigate();
  const emojiFallingSound = new Audio(emojiFallingSoundFile);
  const pipeTravelSound = new Audio(pipeTravelSoundFile);

  const determineQuadrant = (importance, deadline) => {
    const now = new Date();
    const isUrgent = deadline < now.setDate(now.getDate() + 3); // Within 3 days is urgent
    if (importance >= 3 && isUrgent) return 'important-urgent';
    if (importance >= 3) return 'important-not-urgent';
    if (isUrgent) return 'not-important-urgent';
    return 'not-important-not-urgent';
  };

  const handleAddTask = () => {
    if (!task.trim()) {
      alert('Task cannot be empty');
      return;
    }

    const newTask = {
      id: Date.now(),
      task,
      importance,
      deadline: deadline.toISOString(),
      quadrant: determineQuadrant(importance, deadline),
    };

    const updatedTasks = [...tasks, newTask];
    setTasks(updatedTasks);
    localStorage.setItem('tasks', JSON.stringify(updatedTasks));
    setTask('');
    setImportance(0);
    setDeadline(new Date());

    // Show emoji and play the sound
    setEmojiVisible(true);
    emojiFallingSound.play();

    // Hide the emoji after 2 seconds
    setTimeout(() => {
      setEmojiVisible(false);
    }, 2000);
  };

  const handlePipeClick = () => {
    pipeTravelSound.play();
    navigate('/matrix');
  };

  return (
    <div className="add-task-container">
      <label className="add-task-label">Ok so...</label>
      <input
        type="text"
        value={task}
        onChange={(e) => setTask(e.target.value)}
        placeholder="Enter task..."
        className="add-task-input"
      />

      <label className="add-task-label">Important?</label>
      <Rating
        count={5}
        value={importance}
        onChange={(value) => setImportance(value)}
        size={30}
        activeColor="#ffd700"
        className="add-task-rating"
      />

<label className="add-task-label">For when?</label>
<div className="date-picker-container">
  <Flatpickr
    data-enable-time
    value={deadline}
    onChange={(date) => setDeadline(date[0])}
    options={{ enableTime: true, dateFormat: 'd/m/Y H:i' }}
    className="date-picker"
  />
</div>

      <button
        onClick={handleAddTask}
        className="add-task-button add-task-submit"
      >
        Add Task
      </button>

      <img
        src={pipeImage}
        alt="Pipe to Matrix"
        className="add-task-pipe"
        onClick={handlePipeClick}
      />

      {/* Falling emoji */}
      {emojiVisible && <div className="emoji-display">📝</div>}
    </div>
  );
}

export default AddTask;