import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import AddTask from './components/AddTask';
import Matrice from './components/Matrice';
import InfoIcon from './components/InfoIcon'; // Import du composant InfoIcon
import logo from './assets/whatdoIdonow.png';

// Utility functions for cookie management
function setCookie(name, value, days) {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  document.cookie = `${name}=${JSON.stringify(value)};${expires};path=/`;
}

function getCookie(name) {
  const cookies = document.cookie.split("; ");
  for (let cookie of cookies) {
    const [key, val] = cookie.split("=");
    if (key === name) {
      return JSON.parse(val);
    }
  }
  return null;
}

function App() {
  const [tasks, setTasks] = useState(() => getCookie("tasks") || []);
  const location = useLocation(); // Hook to get current route

  const saveTasksToCookie = (updatedTasks) => {
    setCookie("tasks", updatedTasks, 7); // Save for 7 days
  };

  const addTask = (newTask) => {
    const updatedTasks = [...tasks, newTask];
    setTasks(updatedTasks);
    saveTasksToCookie(updatedTasks);
  };

  const deleteTask = (taskId) => {
    const updatedTasks = tasks.filter((task) => task.id !== taskId);
    setTasks(updatedTasks);
    saveTasksToCookie(updatedTasks);
  };

  useEffect(() => {
    saveTasksToCookie(tasks); // Ensure tasks are synced to cookies
  }, [tasks]);

  return (
    <div className="container">
      {/* Conditionally render the logo */}
      {location.pathname === "/" && (
        <img src={logo} alt="Logo WhatDoIDoNow" className="logo" />
      )}

      {/* InfoIcon always visible */}
      <InfoIcon />

      {/* Routing des composants */}
      <Routes>
        <Route
          path="/"
          element={<AddTask tasks={tasks} addTask={addTask} />}
        />
        <Route
          path="/matrix"
          element={<Matrice tasks={tasks} deleteTask={deleteTask} />}
        />
      </Routes>
    </div>
  );
}

// Wrap App component with Router
function AppWithRouter() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWithRouter;